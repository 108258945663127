import React, { useState, useEffect } from 'react';
import styled from "styled-components";

const Image = styled.img`
  height: 18rem;
  object-fit: contain;
  padding: 1vw;
  @media only screen and (min-width: 769px) {
    width: 30%;
  }
`;

function ProductImage({ productId, alternative }) {
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    // Ürün değiştiğinde resim URL'sini yeniden ayarla
    const timestamp = Date.now(); // Benzersiz bir parametre ekleyin
    setImageUrl(`/catalog/Products/${productId}.jpg?v=${timestamp}`);
  }, [productId]);
  const replaceImage = (error) => {
    //replacement of broken Image
    error.target.src =  "https://www.extracashandcarry.com/catalog/Products/noproduct.jpg";
  };
  return <Image src={imageUrl} alt={alternative} onError={replaceImage} />;
}

export default ProductImage;